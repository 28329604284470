import React, { useState, useEffect } from "react";
import PaymentForm from "./paymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const PUBLIC_KEY =
  "pk_test_51McW0JJ1yWXXxAIeergTlBl6SAjhloHHIXEiQ0Hh9W7mIbKxVJw2Oo9UtxCbmySvQgP2BHiBy03jmTsAEGTGYF5m00YL3lftQk";

const stripeTestPromise = loadStripe(PUBLIC_KEY);
const Subscription = ({ data, token }) => {
  return (
    <div>
      <Elements stripe={stripeTestPromise}>
        <PaymentForm data={data} token={token} />
      </Elements>
    </div>
  );
};

export default Subscription;
